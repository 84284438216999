import React, {useState, useEffect} from "react";
import { useCart } from "react-use-cart";
import { formatPrice } from "../utils";
import { Link, navigate } from "gatsby";
import QuantityControl from "./quantityControl";

const Cart = () => {
  const [alert, setAlert] = useState({});
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();

  const totalPriceNumber = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const doesTotalPriceMeetMinimalPriceValue = Math.round(totalPriceNumber) >= 30; // at least 30lv
  const handleCheckout = () => {
    if(doesTotalPriceMeetMinimalPriceValue) {
      navigate("/checkout/");
    } else {
      setAlert({
        type: "primary",
        message: `Моля, имайте предвид, че минималната сума за поръчка е 30 лв. Остават Ви ${(30 - Math.round(totalPriceNumber))} лв. за достигането на тази минимална сума.`,
      });
    }
  }

  // clear alert if you change quantity
  useEffect(() => {if(doesTotalPriceMeetMinimalPriceValue) setAlert({})}, items);

  return (
    <div className="container">
      {/* Create alert */}
      {alert && alert.type && (
        <div className={`alert alert-${alert.type} position-sticky top-0`} role="alert">
          {alert.message}
        </div>
      )}
      <h1 className="m-2 p-2">Избрани продукти</h1>
      {isEmpty && <p>Количката е празна.</p>}
      <div className="row">
        <div className="col-12">
        <div className="container cart">
          <div className="row d-none d-lg-flex">
              <p className="col-4">Продукт</p>
              <p className="col-2">Цена, лв.</p>
              <p className="col-2">Количество</p>
              <p className="col-2">Сума</p>
              <p className="col-2">Премахване</p>
              <span className="border border-light m-2"></span>
          </div>

          {items.map((item, i) => (<div className="row" key={item.id}>
            <div className="col-lg-4">
              <Link to={"/bg-product-details-" + item.data_id} className="text-decoration-none text-black">
                <div className="row">
                  <div className="col-6">
                    {item.product_image_link && 
                      (<img 
                        src={item.product_image_link} 
                        alt="product image" 
                        className="img-fluid"
                      />)
                    }
                  </div>
                  <div className="col-6 p-2">
                    <span className="fs-5">{item.product_name}</span>
                  </div>
                </div>
              </Link>
            </div>
              <div className="col-lg-2 fw-bold">{formatPrice(item.price)}</div>
              <span className="border border-light m-2 d-lg-none"></span>
              <div className="col-lg-2"><QuantityControl product={item} initalQuantity={item.quantity} type="cart"/></div>
              <span className="border border-light m-2 d-lg-none"></span>
              <div className="col-lg-2 fw-bolder">{formatPrice(item.price * item.quantity)}</div>
              <span className="border border-light m-2 d-lg-none"></span>
              <div className="col-lg-2"><button onClick={() => removeItem(item.id)} className="btn btn-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash text-primary" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <span className="fs-6 text-primary d-lg-none fst-italic" > Премахване</span>
              </button></div>
              <span className="mb-5 d-lg-none"></span>
              <span className="border border-light m-2"></span>
          </div>))}
        </div>
        </div>
      </div>  <p className="fs-5">Обща сума: <strong className="fw-bolder">{formatPrice(totalPriceNumber)}</strong></p>
    
      <div className="row m-5 pb-5">
        <div className="col-6"><Link className="btn btn-lg btn-secondary text-white"to="/">Обратно в магазина</Link></div>
        <div className="col-6"><button className="btn btn-lg btn-secondary text-white" onClick={handleCheckout}>Поръчай</button></div>
      </div>
    </div>
  );
};

export default Cart;